import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["hasPerm", "isNumerisak", "isGE"]),
    datesNoLicEns() {
      if (this.isGE || this.isNumerisak) {
        const year = this.$moment().year();
        return {
          debut: `${year}-06-01`,
          fin: `${year}-08-15`,
        };
      }

      return null;
    },
    canOrderLicence() {
      if (!this.hasPerm("can_commander_licence_supp")) {
        return false;
      }

      if (this.datesNoLicEns) {
        return !this.$moment().isBetween(this.datesNoLicEns.debut, this.datesNoLicEns.fin, "days", "[]");
      }

      return true;
    },
  },
  methods: {
    /**
     * Définit le tooltip du bouton de commande de licences enseignant.
     * @param {Object} product Information du produit avec les licences.
     * @returns {String} - Contenu du tooltip.
     */
    tooltip(product) {
      if (product.demandes.length) {
        return this.$t("ask-licence.demande-en-cours-de-traitement", 1, {
          user: this.nomDemande, date: this.dateDemande,
        });
      }

      if (
        this.datesNoLicEns
        && this.$moment().isBetween(this.datesNoLicEns.debut, this.datesNoLicEns.fin, "days", "[]")
      ) {
        return this.$t("licence.vous-ne-pouvez-pas-commander-lic-enseignant-du-x-au-y", {
          dateDebut: this.$moment(this.datesNoLicEns.debut).format("DD/MM"),
          dateFin: this.$moment(this.datesNoLicEns.fin).format("DD/MM"),
        });
      }

      return undefined;
    },
  },
};
